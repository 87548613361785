<template>
  <div style="background-color: white !important">
   <div class="page-header page-header-small heroGalleryBackgroundImage globalHeaderMobile" style="background-image: url('img/gallery.jpg');"> 
    <div class="container mt-5">
      <div class="row d-flex justify-content-left mt-5 pt-5">
        <div class="col-md-6 col-sm-12 align-self-center" style="text-align: left">
          <h1 class="smallerMobileH1">Check Out Our Farm</h1>
           <hr style="background-color: white !important">
           <h3>We take pride in our services and our ability to maintain a healthy environment for your horse.</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container" >
    <gallery :images="images" :index="index" @close="index = null"></gallery>
     <div class="row mt-5 pb-5">
      <div
         class="image col-lg-4 col-md-6 col-sm-12"
         v-for="(image, imageIndex) in images"
         :key="imageIndex"
         @click="index = imageIndex"
         :style="{ backgroundImage: 'url(' + image + ')', width: '600px', height: '300px' }"
       >
     </div>
    </div>
 </div> 


</div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
import { Carousel, CarouselItem } from 'element-ui';
import VueGallery from 'vue-gallery';
export default {
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    'gallery': VueGallery
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      },
       images: [
          'img/gallery/barn2.jpg',
          'img/gallery/sign.jpg',
          'img/gallery/barn3.jpg',
          'img/gallery/field2.jpeg',
          'img/gallery/newKi.jpg',
          'img/gallery/1d.jpg',
          'img/gallery/barn.jpg', 
          'img/gallery/1c.jpg',
          'img/gallery/1f.jpg',      
          'img/gallery/JandK.jpg',
          'img/gallery/three.jpg',
          'img/gallery/1b.jpg',
          'img/gallery/four.jpg',
          'img/gallery/1a.jpg',
          'img/gallery/creek.jpg',
          'img/gallery/five.jpg',
          'img/gallery/six.jpg',
          'img/gallery/barn4.jpg'
        ],
        index: null
    };
  },
  methods: {
    galleryModal() {

    }
  },
  created() {
    //console.log('fire');
  }
};
</script>
<style>
  a.close {
    color: white !important;
    text-decoration: none !important;
  }
  a.next {
    color: white !important;
    text-decoration: none !important;
  }
  a.prev {
    color: white !important;
    text-decoration: none !important;
  }

  .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
  }
  .image:hover {
    cursor: pointer;
    opacity: .5;
    transition: 0.1s;
  }



  @media only screen and (max-width: 989px) {
  .carousel-height {
    height: 350px;
   }
 }

</style>
