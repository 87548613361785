<template>
  <div>
    <div class="page-header page-header-medium heroBackgroundImage" style="background-image: url('img/nc.jpg')">
      <!-- hero-BG-stencil.png -->
      <div class="content-center">
      <div class="container">
        <div class="row ">
          <div class="col-md-6 col-sm-6 ml-auto mr-auto text-center">
            <img class="heroLogo" src="img/logo/logoScaled.png">
          </div>
        </div>
        <div class="row"> 
          <div class="col-md-6 ml-auto mr-auto text-center" id="">
            <h1 class="smallerMobileH1">Welcome To <br> North Star Run</h1>
            <h3 class="lightColor erlText">Equine Retirement Living</h3>
          </div>
        </div>
        <div class="row ml-auto mr-auto" style="margin-top: -15px;"> 
           <div class="col-md-12 ">
            <h3 class="stateText">Statesville, NC</h3> 
          </div>
        </div>
        <div class="row ml-auto mr-auto" style="margin-top: -10px;">       
          <div class="col-md-6 col-sm-12 mobileMarginBtn">
            <a href="#">
              <h4>
               <n-button onclick="document.location+='#ctaScroll';return false;"  class="darkBG text-center heroBtn">Choose Retirement?</n-button>
              </h4>
           </a>
          </div>
          <div class="col-md-6 col-sm-12 mobileMarginBtn" >
            <router-link to="/About-Us">
              <h4>
                <n-button class="darkBG text-center heroBtn">About Us</n-button>
              </h4>
           </router-link>
          </div>
        </div>
      </div>
      <div ></div>
      </div>

    </div>
    <div class="section section-about-us" id="ctaScroll">
      <div class="container">
         <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title darkColor"> Are You Considering Retirement For Your Horse?</h2>
            <p class="">
                Considering Retirement Living for your horse?  Wondering if it’s the right choice for your equine
                partner?  It’s a difficult decision to make. I know, I’ve been there. In 2017 I retired my horse Ki to a
                retirement farm. I anguished over that decision, but I knew it was the right one when I saw Ki just “being
                a horse” in a peaceful, natural herd environment. He’s thriving and happy and I know that’s what he
                deserves.
            </p>
          </div>
        </div> 
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6 hide-from-mobile">
              <div
                class="image-container image-left"
                style="background-image: url('img/gallery/newKi.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary">
                  "One of the best things about North Star Run is that we are very cognizant of the herd dynamic. 
                  We do our very best to find your horse the herd mates that maximize safety, comfort and health."
                  <br />
                  <br />
                  <small>-Jenni Burch</small>
                </p>
              </div>
             
              <!-- Second image on the left side of the article -->
              <div
                class="image-container"
                style="background-image: url('img/gallery/barn.jpg')"
              ></div>
            </div>
            <div class="col-md-6 hide-from-desktop">
              <div
                class="image-container image-left"
                style="background-image: url('img/gallery/six.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary">
                  "One of the best things about North Star Run is that we are very cognizant of the herd dynamic. 
                  We do our very best to find your horse the herd mates that maximize safety, comfort and health."
                  <br />
                  <br />
                  <small>-Jenni Burch</small>
                </p>
              </div>
             
              <!-- Second image on the left side of the article -->
              <div
                class="image-container"
                style="background-image: url('img/gallery/barn.jpg')"
              ></div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right"
                style="background-image: url('img/gallery/four.jpg')"
              ></div>
                 
              <br>
               <p>
                Maybe your horse is older, and he’s done an amazing job taking care of you over the years.  Now you
                want to give back to him in the most loving way.
                <br><br>

                Or, maybe your horse is younger but an injury is keeping him from doing his job.
                <br><br>

                Or, perhaps you just need a reprieve from the high boarding costs in many parts of our country.
                <br><br>
    
                Or, maybe your horse just needs some much needed down time to refresh and relax.
              </p>
              <br>
              <p>
                Whatever the reason you are considering equine retirement, just know I have found it’s always a
                wonderful, selfless answer to many different scenarios.  You will be offering him a natural, peaceful way
                of life in a safe environment.
              </p>  
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section" id="carousel"  style="background-size: cover; background-image: url('https://cdn.pixabay.com/photo/2014/01/17/19/01/tree-247122_1280.jpg')">
    <div class="container">
      
     
          <el-carousel class="">
            <el-carousel-item>
              <img class="d-block carousel-images" src="img/gallery/five.jpg" alt="First slide" />
              <div class="carousel-caption d-none d-md-block"> 
               <!--  <h5>Picture 1</h5> -->
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <img class="d-block carousel-images" src="img/gallery/JandK.jpg" alt="Second slide" />
              <div class="carousel-caption d-none d-md-block">
               <!--  <h5>Picture 2</h5> -->
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <img class="d-block carousel-images" src="img/gallery/three.jpg" alt="Third slide" />
              <div class="carousel-caption d-none d-md-block">
                <!-- <h5>Picture 3</h5> -->
              </div>
            </el-carousel-item>
          </el-carousel>
       
    </div>
  </div>
  <!-- reviews -->
  <div class="section darkBodyBG section-about-us text-center" >
      <div class="container">
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto " style="">
            <h2 class="title darkColor" style="background-color: white; border-radius: 20px; padding: 10px;">
             Reviews From Our Clients 
             </h2>
          </div> 
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 ml-auto mr-auto padding-test-area">
             <img class="slider-logos rounded-circle img-fluid shadow-lg" src="img/testimonial/testimonial3.jpg">
             <br>
             <img class="slider-stars" src="img/testimonial/stars.png">
             <br>
             <span class="darkColor" style="font-weight: 700; font-size:20px;">Kimberly M.</span>
              <div class="col-md-11 ml-auto mr-auto text-center">
               <p>"If you are looking for a retirement farm that is willing to go the extra mile for your horse, North Star Run is the place to be. My horse arrived at NSR with injuries and needed special attention for the first couple of months he was there. Wendy and Jenni communicated with me daily, took care of all my horses special needs and I was provided pictures multiple times per week to show his healing and progress. I couldn’t be happier with the service they provide… My boy Jax is fly sprayed, masked and groomed when needed. The horses at NSR are looked over daily, the fields are lush with green grass and the run-ins are extra large. Thank you NSR, you have made being away from my boy easier just knowing that he’s in your caring hands."</p>
              </div>
       </div>
          <div class="col-lg-12 col-md-12 ml-auto mr-auto padding-test-area">
          <img class="slider-logos rounded-circle img-fluid shadow-lg" src="img/testimonial/testimonial2.jpg" >
           <br>
            <img class="slider-stars" src="img/testimonial/stars.png">
            <br>
            <span class="darkColor" style="font-weight: 700; font-size:20px;">Lisa G.</span>
            <div class="col-md-11 ml-auto mr-auto text-center">
             <p>"I am so happy with North Star Run. Jenni and Wendy have started a true Retirement facility with a hands-on approach for our horses. My horse LB is only 13 and has kissing spine. He has a lot of life left in him and at North Star Run he has absolutely flourished. Jenni pursued a degree in Animal Science an Equine Industry option and uses all of her knowledge to keep our horses safe, comfortable and happy. They make sure your horse is maintaining weight and getting along with others as well as a farrier, all shots, dentist, blankets, and supplements are given. The attention to detail is undeniable. It has been a great piece of mind that my horse is there under such great care to live out his best life.<br>
             Thank you for being so loving and caring of our horses. It’s a true blessing in my life."</p>
            </div>
        </div>
         <div class="col-lg-12 col-md-12 ml-auto mr-auto padding-test-area">
             <img class="slider-logos rounded-circle img-fluid shadow-lg" src="img/testimonial/testimonial1.jpg">  
               <br>
             <img class="slider-stars" src="img/testimonial/stars.png">
               <br>
             <span class="darkColor" style="font-weight: 700; font-size:20px;">Daphne W.</span>
             <div class="col-md-11 ml-auto mr-auto text-center">
                <p>"I was initially terrified of sending my horse "away" to retirement.  He isnt the sweetest boy.  Pain had made him a bit snarky.  No one's favorite but mine.  You have to earn his trust.  I was afraid no one would "get him" and therefore would just be a "throw away."  At North Star Run, my Sammy is happy, understood, and much less snarky overall.  And even on his snarky days, he is loved and respected.  All his boo-boo's are doctored.  He is hands-on checked every day.  They go above and beyond keeping up with my hard keeper.  He is shiny, happy, and loved.  And I can breathe easy knowing this.  I cant recommend Jenni, Wendy and North Star Run Equine Retirement highly enough to give them the accolades they truly deserve.  Thank you North Star Run!!"</p>
             </div>
       </div>
      </div>
    </div>
  </div>

    <div class="section section-contact-us text-center silverBG">
      <div class="container">
        <div class="col-md-12 col-sm-12" >
        <h2 class="title darkColor">Check Out Our Rates!</h2>
        <p class=" darkColor">Take a look at our rates and what services we provide your horse.</p>
     
        
          <router-link to="/Pricing">
            <h4 class="custom-h4-pricing mb-4">
              <n-button class="darkBG text-center heroBtn" >Pricing</n-button>
            </h4>
           </router-link>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
import { Carousel, CarouselItem } from 'element-ui';
import axios from "axios";
export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  },
  data() {
    return {
    };
  },
  methods: { 
  }
};
</script>
<style>
.custom-h4-pricing {
  margin-top: 0px !important
}
.el-carousel__container {
    height: 600px !important;
}

.carousel-height {
    height: 600px !important;
}

.carousel-images {
   object-fit: cover !important;
   width: 85%;
   margin-left: auto;
   margin-right: auto;
   height: 100%;
}

  .el-carousel__arrow {
    background-color: #2a425e;
    color: white !important;
    border: 1px solid white;
  }

@media only screen and (max-width: 600px) {
  .hide-from-mobile {
    display: none;
    border:3px solid red !important;
  }
}
@media only screen and (min-width: 600px) {
  .hide-from-desktop {
    display: none;
    border: 3px solid red !important;
  }
}
@media only screen and (max-width: 1201px) {

.el-carousel__arrow {
    color: white !important;
    border: 2px solid white;
}

.carousel-images {
    width: 100%;
    height: 700px !important;
  }
}


  
  @media only screen and (max-width: 993px) {
  .carousel-height {
    height: 400px !important;
   }
   .el-carousel__container {
    height: 400px !important;
  }
  .carousel-images {
    height: 400px !important;
   }
}

@media only screen and (max-width: 480px) {
  .carousel-height {
    height: 300px !important;
   }
   .el-carousel__container {
    height: 300px !important;
  }
  .carousel-images {
    height: 300px !important;
  }

  .custom-h4-pricing {
  margin-top: 44px !important
  }
}

@media only screen and (max-width: 375px) {
  .carousel-height {
    height: 200px !important;
   }
   .el-carousel__container {
    height: 200px !important;
  }
  .carousel-images {
    height: 200px !important;
  }
}

 @media only screen and (max-width: 1920px) {
  .heroLogo {
    width: 350px;
   }
 }
  .erlText {
    line-height: 10px;
    font-size: 28px; 
    margin-top: -15px;
  }
  .stateText {
    font-size: 30px; 
  }

  /*contact form*/
i.input-group-text  {
  border: 1px solid #2a425e !important;
  border-right: none !important;
}

input.form-control {
  border: 1px solid #2a425e !important;
  border-left: none !important;
}

textarea.form-control, .el-date-picker .el-input textarea.el-input__inner, .form-group textarea.el-input__inner {
  border-bottom: 1px solid #2a425e !important;
}

  @media only screen and (max-width: 575px) {
   .heroLogo {
    width: 175px;
   }
   .stateText {
    font-size: 20px;
    line-height: 10px; 
   }
  }
  @media only screen and (max-width: 380px) {
   .heroLogo {
     width: 150px;
   }
  }


 @media only screen and (max-width: 600px) {

 }

 @media only screen and (max-width: 480px) {
  

  .erlText {
    line-height: 10px;
    font-size: 17.5px; 
    margin-top: 5px;
  }
 }



</style>
