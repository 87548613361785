<!-- <template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll" 
    menu-classes="ml-auto"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        Now Ui Kit
      </router-link>
      <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="popover-body">
          Designed by Invision. Coded by Creative Tim
        </div>
      </el-popover>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <a
          class="nav-link"
          href="https://www.creative-tim.com/product/vue-now-ui-kit"
          target="_blank"
        >
          <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          <p>Download</p>
        </a>
      </li>
      <drop-down
        tag="li"
        title="Components"
        icon="now-ui-icons design_app"
        class="nav-item"
      >
        <nav-link to="/">
          <i class="now-ui-icons business_chart-pie-36"></i> All components
        </nav-link>
        <a
          href="https://demos.creative-tim.com/vue-now-ui-kit/documentation"
          target="_blank"
          class="dropdown-item"
        >
          <i class="now-ui-icons design_bullet-list-67"></i> Documentation
        </a>
      </drop-down>
      <drop-down
              tag="li"
              title="Examples"
              icon="now-ui-icons design_image"
              class="nav-item"
      >
        <nav-link to="/landing">
          <i class="now-ui-icons education_paper"></i> Landing
        </nav-link>
        <nav-link to="/login">
          <i class="now-ui-icons users_circle-08"></i> Login
        </nav-link>
        <nav-link to="/profile">
          <i class="now-ui-icons users_single-02"></i> Profile
        </nav-link>
      </drop-down>
      <li class="nav-item">
        <a
          class="nav-link btn btn-neutral"
          href="https://www.creative-tim.com/product/vue-now-ui-kit-pro"
          target="_blank"
        >
          <i class="now-ui-icons arrows-1_share-66"></i>
          <p>Upgrade to PRO</p>
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Twitter"
          data-placement="bottom"
          href="https://twitter.com/CreativeTim"
          target="_blank"
        >
          <i class="fab fa-twitter"></i>
          <p class="d-lg-none d-xl-none">Twitter</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Like us on Facebook"
          data-placement="bottom"
          href="https://www.facebook.com/CreativeTim"
          target="_blank"
        >
          <i class="fab fa-facebook-square"></i>
          <p class="d-lg-none d-xl-none">Facebook</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Instagram"
          data-placement="bottom"
          href="https://www.instagram.com/CreativeTimOfficial"
          target="_blank"
        >
          <i class="fab fa-instagram"></i>
          <p class="d-lg-none d-xl-none">Instagram</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    DropDown,
    Navbar,
    NavLink,
    [Popover.name]: Popover
  }
};
</script>

<style scoped></style>
 -->




 <template>
  <navbar
    position="fixed"
    type="primary" 
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
    class="navBG"
  >
    <template >
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img
              v-lazy="'img/navBarLogoScaled.png'"
              alt="North Star Run"
              class="navbar-logo-resposive"
            />
      </router-link>
    </template>
    <template slot="navbar-menu" id="navbarWrapper">
      <li class="nav-item">
        <a class="nav-link">
          <nav-link to="/">
             <i style="font-size: 17px;" class="now-ui-icons shopping_shop desktopDisplayNoIcons"></i>  
            <p class="navbarText">Home</p>
          </nav-link>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <nav-link to="/About-Us">
            <i style="font-size: 17px;" class="now-ui-icons emoticons_satisfied desktopDisplayNoIcons"></i>  
            <p class="navbarText">About Us</p>
          </nav-link>
        </a>
      </li>
       <li class="nav-item">
        <a class="nav-link">
          <nav-link to="/Gallery">
             <i style="font-size: 17px;" class="now-ui-icons media-1_album desktopDisplayNoIcons"></i> 
            <p class="navbarText">Gallery</p>
          </nav-link>
        </a>
      </li> 
       <li class="nav-item">
        <a class="nav-link">
          <nav-link to="/FAQ">
           <i style="font-size: 17px;" class="now-ui-icons users_circle-08 desktopDisplayNoIcons"></i> 
            <p class="navbarText">FAQ</p>
          </nav-link>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <nav-link to="/Pricing">
            <i style="font-size: 17px;" class="now-ui-icons business_money-coins desktopDisplayNoIcons"></i> 
            <p class="navbarText">Pricing</p>
          </nav-link>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <nav-link to="/Contact">
            <i style="font-size: 17px;" class="now-ui-icons ui-2_chat-round desktopDisplayNoIcons"></i> 
            <!-- <br class="md-screens-br"> -->
            <p class="navbarText" >Contact Us</p>
          </nav-link>
        </a>
      </li> 
      <li class="nav-item socialIcons">
        <a
          class="nav-link"
          rel="tooltip"
          title="Like us on Facebook"
          data-placement="bottom"
          href="https://www.facebook.com/pages/category/Equestrian-Center/North-Star-Run-Equine-Retirement-Farm-102099024901274/"
          target="_blank"
        >
        <nav-link>
          <i class="fab fa-facebook-square social-icons-size" ></i>
          <p class="d-lg-none d-xl-none" style="color: white;">Facebook</p>
        </nav-link>
        </a>
      </li>
    </template>
  </navbar>
</template> 

<script>
import {Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Navbar,
    NavLink
  },
  data() {
    return {
    }
  },
  methods: {
  }
};
</script>

<style scoped>
/*remove link style around logo on click*/
*:focus {
  outline:none !important
}
@media only screen and (min-width: 1200px) {
  li.nav-item.socialIcons {
    margin-top: -5px !important;
  }
}
a.nav-link {
  float: left;
  padding: 0px 0px !important;
  margin-top: 10px;
}


.navbarText {
  color: white;
  padding-top: 5px;
  font-size: 16px;
}

.desktopDisplayNoIcons {
  display: none;
}

.dropdown-item:hover {
  background-color: transparent !important; 

}
  
.navbar-logo-resposive {
  height:80px !important;
}

.dropdown-item {
    background-color: transparent !important;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: transparent !important;
}

.navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons {
    top: 4px;
    /*font-size: 1.5em !important;*/
    color: white !important;
}

li.nav-item.socialIcons {
  padding: 3px !important;
}

i.social-icons-size {
  font-size: 25px !important;
}
.md-screens-br {
  display:none;
}
@media only screen and (max-width: 1200px) {
  .navbar-logo-resposive {
  height:80px !important;

  }
  .md-screens-br {
    display: block
  }
  .navbarText {
    color: white;
    padding-top: 10px;
    font-size: 13px;
  }
}
/*@media only screen and (max-width: 1010px) {
  .navbar-logo-resposive {
  height:60px !important;
  }

}*/
@media only screen and (max-width: 989px) {
  .navbar-logo-resposive {
    height:85px !important;

  }
  a.nav-link {
    width: 140px;
    margin-left: -5px !important; 
  }
  .md-screens-br {
    display: none
  }
  .navbarText {
    margin-left:5px; 
 }
 .desktopDisplayNoIcons {
  display: inline-block;
 }
 i.social-icons-size {
  font-size: 20px !important;
  margin-left: -4px;
}
}

@media only screen and (max-width: 600px) {
  .navbar-logo-resposive {
  height:55px !important;

  }
}



</style>
