<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <div style=" font-size: 16px;" class="copyright">
       
        &copy; {{ year }} NSR and 
       <a style="text-decoration: none !important;" href="https://ilovecode.tech/" target="_blank" rel="noopener"
          >iLoveCode</a>

      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
  footer {
    background-color: #2a425e !important
  }
</style>
