<template>
  <div>
    <div class="page-header thereafter page-header-small heroBackgroundImage globalHeaderMobile" style="background-image: url('img/field22.jpg')">
      <!-- <div class="content-center">
      <div class="container">
   
            <h1 class="info-title smallerMobileH1">Pricing</h1>
            <hr style="background-color: white !important"> 
            <h3 style="color: white">Our boarding fee to provide exceptional, quality care for your horse is <br>$350 per month.</h3>
      </div>
    </div> -->
    <div class="container mt-5 ">
      <div class="row d-flex justify-content-left mt-5 pt-5 mb-5">
        <div class="col-md-8 col-sm-12 align-self-center" style="text-align: left">
          <h1 class="smallerMobileH1">Pricing</h1>
           <hr style="background-color: white !important">
           <h3>Our boarding fee to provide exceptional, quality care for your horse is $425 per month. We require a refundable security deposit of $425 as well as a refundable burial fee of $1000.</h3>
        </div>
      </div>
    </div>
    </div>
    <div class="section">
      <div class="container">
        <h2 class="title darkColor">Our Services Include:</h2>
        <ul>
          <li>Square baled hay fed year round as needed (no round bails)</li>
          <li>Nutrina Pro Force Senior Feed for our retirees needing extra help keeping weight on</li>
          <li>Trimming Every 6 Weeks</li>
          <li>Rotational Deworming 4 times per year</li>
          <li>Access to salt and mineral blocks</li>
          <li>Fly spray in the summer (provided by us) and we use fly masks as well (provided by you)</li>
          <li>Blanketing in winter, as needed (provided by you)</li>
          <li>Daily hands on inspection of each horse</li>
          <li>Rotational Pasture Grazing</li>
          <li>Semi-annual or annual vaccination and dental work are the only extra costs <br>
          (Barring any unforeseen accidents or situations that require a Vet or daily after care, you will be billed separately)    
          </li>  
        </ul>
        
      </div>
    </div>



    <!-- <mdb-container>
    <h4 class="pb-2">Cascading cards</h4>
    <mdb-row>
      <mdb-col sm="4">
        <mdb-card wide>
          <div class="img-wrapper">
          <mdb-view>
            <mdb-card-image src="https://mdbootstrap.com/img/Photos/Horizontal/People/6-col/img%20%283%29.jpg" alt="Card image cap"></mdb-card-image>
            <mdb-mask flex-center waves zoom="white-slight"></mdb-mask>
          </mdb-view>
         </div>
          <mdb-card-body class="text-center" cascade>
            <mdb-card-title><strong>Alice Mayer</strong></mdb-card-title>
            <h5 class="indigo-text"><strong>Photographer</strong></h5>
            <mdb-card-text>Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium doloremque
              laudantium, totam rem aperiam.</mdb-card-text>
            <a class="fa-lg li-ic px-2">
              <mdb-icon fab icon="linkedin" /></a>
            <a class="fa-lg tw-ic px-2">
              <mdb-icon fab icon="twitter" /></a>
            <a class="fa-lg fb-ic px-2">
              <mdb-icon fab icon="facebook" /></a>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col sm="4">
        <mdb-card narrow>
          <mdb-view hover cascade>
            <mdb-card-image src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(147).jpg" alt="Card image cap"></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body cascade>
            <h5 class="pink-text pb-2 pt-1">
              <mdb-icon fab icon="utensils" /> Culinary</h5>
            <mdb-card-title>Cheat day inspirations</mdb-card-title>
            <mdb-card-text>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit
              laboriosam, nisi ut aliquid ex ea commodi.</mdb-card-text>
            <mdb-btn color="unique">Button</mdb-btn>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col sm="4">
        <mdb-card cascade>
          <mdb-view hover cascade>
            <mdb-card-image src="https://mdbootstrap.com/img/Photos/Others/men.jpg" alt="Card image cap"></mdb-card-image>
            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
          </mdb-view>
          <mdb-card-body class="text-center" cascade>
            <mdb-card-title><strong>Billy Cullen</strong></mdb-card-title>
            <h5>Web developer</h5>
            <mdb-card-text>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus, ex,
              recusandae. Facere modi sunt, quod quibusdam.</mdb-card-text>
            <mdb-btn tag="a" floating small class="btn-fb">
              <mdb-icon fab icon="facebook" />
            </mdb-btn>
            <mdb-btn tag="a" floating small class="btn-tw">
              <mdb-icon fab icon="twitter" />
            </mdb-btn>
            <mdb-btn tag="a" floating small class="btn-dribbble">
              <mdb-icon fab icon="dribbble" />
            </mdb-btn>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </mdb-container> -->


  </div>
</template>
<script>
export default { 
  components: {
  }
};
</script>
<style>
 li {
  font-size: 1.3em;
 }
</style>
